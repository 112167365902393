import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "./../components/seo"

import { Link, navigate } from "gatsby"

import { useDispatch, useSelector } from "react-redux"
import { login } from "./../actions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons"

export const ConnexionPage = () => {
  const dispatch = useDispatch()
  const identifiants = useSelector(s => s.identifiants)
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    try {
      let resp = await fetch(process.env.GATSBY_API_URL + "/index.php?controller=authentification", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "connect",
          email: email,
          password: password,
          site: process.env.SITE,
        }),
      })
      resp = await resp.json()
      if (resp.status == "error") {
        setError(resp.message)
      } else {
        dispatch(login(resp))
      }
    } catch (error) {
      setError("Une erreur de connexion s'est produite, veuillez nous contacter. Code : ER_CONNEXION_1")
      console.log("Request failed", error)
    }
    setLoading(false)
  }

  if (identifiants.identifiants && identifiants.identifiants.firstname && identifiants.identifiants.lastname) {
    if (typeof window !== "undefined") {
      const redirect = new URLSearchParams(window.location.search).get("redirect")
      if (redirect === null) {
        navigate(`/`)
      } else {
        navigate(redirect)
      }
    }
  }

  return (
    <>
      <Layout banner={false}>
        <SEO title="Connexion à votre espace personnel" description="Accédez à vos commandes de cadres, informations personnelles" />
        <div className="columns is-centered login" style={{ marginTop: "6rem" }}>
          <div className="section column is-half couleurShadow">
            <h3 className="dotted">
              <svg>
                <use xlinkHref="#connexion" />
              </svg>
              Connexion
            </h3>
            <form method="post" onSubmit={event => handleSubmit(event)}>
              <div className="field">
                <label className="label" htmlFor="email">
                  Adresse email&nbsp;:
                </label>
                <div className="control">
                  <input
                    type="text"
                    id="email"
                    name="username"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                    className="input"
                    placeholder="Votre adresse email"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor="password">
                  Mot de passe&nbsp;:
                </label>
                <div className="control">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    onChange={e => setPassword(e.target.value)}
                    required
                    value={password}
                    className="input"
                    placeholder="Votre mot de passe"
                  />
                </div>
              </div>
              {error && <div className="notification is-danger">{error}</div>}
              <div className="field is-grouped" style={{ marginTop: "2rem" }}>
                <div className="control">
                  <Link
                    to="/apps/mon-compte/init-mdp"
                    style={{
                      fontSize: "0.9rem",
                      position: "relative",
                      top: "0.3rem",
                    }}
                  >
                    Mot de passe oublié&nbsp;?
                  </Link>
                </div>
                <div className="control">
                  <button type="submit" className={`button ${loading ? "is-loading" : ""}`}>
                    <FontAwesomeIcon className="mr-1" icon={faSignInAlt} />
                    Connexion
                  </button>
                </div>
              </div>
              <hr />
              <Link
                to="/acces"
                className="has-text-centered"
                style={{
                  margin: "0 auto",
                  display: "block",
                  position: "relative",
                }}
              >
                <div className="has-text-centered button is-info is-medium is-rounded">Demandez l&apos;accès au site</div>
              </Link>
            </form>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ConnexionPage
